import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../templates/Layout";

import Button from "../components/button/Button"
import TrainingTimes from "../components/training-times/TrainingTimes"
import Sensei from "../components/sensei/Sensei"

import * as styles from "./index.module.css"

export const pageQuery = graphql`
    query Homepage {
        site {
            siteMetadata {
                name
            }
        }
        allMdx(
            filter: {slug: {in: ["ju-jitsu", "gallery", "contact-us"]}}
            sort: {fields: frontmatter___order, order: ASC}
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        summary
                        cta
                    }
                    slug
                }
            }
        }
    }
`


class Homepage extends React.Component {
    render() {
        const data = this.props.data;

        return (
            <Layout
                title={data.site.siteMetadata.name}
                location={this.props.location}
                isHome
            >
                <TrainingTimes
                    isHome
                />

                <Sensei
                    isHome
                    className={styles.sensei}
                />

                <div className={styles.quicklinks}>
                    {data.allMdx.edges.map((item) => {
                        let slug = item.node.slug
                        item = item.node.frontmatter
                        
                        return (
                            <div className={styles.quicklink}>
                                <h3>{item.title}</h3>
                                <p>{item.summary}</p>
                                <Link to={slug}>
                                    <Button
                                        secondary
                                    >
                                        {item.cta}
                                    </Button>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </Layout>
        )
    }
}

export default Homepage

